<template>
  <v-app>
    <PageHeader />
    <v-main>
      <loading v-if="this.emailCredits === 'loading'" />
      <div class="mainBody" v-if="this.messageSent == false">
        <h1 v-if="this.emailCredits !== 'loading'" class="text-center">Send Message</h1>
        <br />
        <div v-if="emailCredits >= 1|| null == true">
          <h3 class="text-center">Available Mail Credits {{emailCredits}}</h3>
          <div class="text-center">
            <v-btn depressed @click="buyCredits">
              <v-icon large>mdi-message-plus</v-icon>buy credits
            </v-btn>
            <buy-credits v-bind:usercards="usercards" ref="buycredits" />
          </div>
          <v-form ref="form" v-model="valid" lazy-validation >
            <div class="text-center">
              <v-row class="justify-center">
                <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="from" label="From" :rules="[v => !!v || 'Item is required']"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  class="pt-1"
                v-if="campers.length  || campers == null"
                chips
                v-model="selectedCampers"
                :items="campers"
                item-text="fullName"
                label="Camper Name*"
                item-value="camperId"
                :rules="[v => !!v || 'Item is required']"
              ></v-autocomplete>
                </v-col>
              </v-row>
              <v-btn depressed @click="addCameper" :color="errorcolor">
                <v-icon large>mdi-account-multiple-plus</v-icon>Add Camper
              </v-btn>
              <h2 class="danger-alert" v-if="nocampers">Please Add Camper</h2>
              <add-camper ref="addcamper" />
              <v-switch v-if="emailCredits >= 2" v-model="reply" :label="`Reply (Will cost additional mail credit)` "></v-switch>
              <h3 v-else>Please add more credits to send a reply</h3>
              <v-textarea   label="Message"       auto-grow v-model="message" :rules="[v => !!v || 'Item is required']">
              </v-textarea>
              <v-btn
                rounded
                class="white"
                :loading="loading1"
                @click="send"
                :disabled="loading1 ||!valid||nocampers"
                color="primary"
              >
                send
                <v-icon>mdi-email-send</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-refresh</v-icon>
                  </span>
                </template>
              </v-btn>
            </div>
          </v-form>
        </div>
        <div class="text-center" v-else-if="emailCredits <= 0 || null">
          <div class="text-center" @click="buyCredits">
            <h2>Please Buy Credits To Continue</h2>
            <v-btn depressed @click="buyCredits">
              <v-icon large>mdi-message-plus</v-icon>buy credits
            </v-btn>
            <buy-credits v-bind:usercards="usercards" ref="buycredits" />
          </div>
          <buy-credits ref v-bind:usercards="usercards" :id="id" />
        </div>
      </div>
      <v-card class="mainBody" v-if="this.messageSent == true">
        <div class="text-center">
          <h1>Thank You Your Message Has Been Sent</h1>
          <br />
          <br />
          <v-btn rounded class="white" @click="sendAnother" color="primary">
            send another message
            <v-icon>mdi-email-send</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import PageHeader from "@/components/Header";
import Footer from "@/components/Footer";
import userService from "../services/Users";
import MessagesService from "../services/Messages";
import addCamper from "@/components/AddCamper";
import buyCredits from "@/components/BuyCredits";
import loading from "../components/Loading";
export default {
  name: "SendMessage",
  components: { PageHeader, Footer, addCamper, buyCredits, loading },
  data() {
    return {
      emailCredits: "loading",
      valid: null,
      from: null,
      reply: false,
      message: null,
      selectedCampers: null,
      campers: [],
      loading1: false,
      messageSent: false,
      usercards: null,
      id: null,
      errorcolor: null,
      nocampers: false
    };
  },
  async mounted() {
    this.userData = (await userService.user()).data;
    this.emailCredits = this.userData.user[0].EmailCredits;
    this.campers = this.userData.usercampers;
    this.userData.usercampers.forEach(
      Camper => (Camper.fullName = Camper.firstName + " " + Camper.lastName)
    );
    this.usercards = this.userData.usercards;
    if (this.campers.length == 0 || null) {
      this.nocampers = true;
      this.errorcolor = "red";
    }
    this.from =
      this.userData.user[0].FirstName + " " + this.userData.user[0].LastName;
  },
  methods: {
    async buyCredits() {
      this.$refs.buycredits.buyCreditsDialog = true;
      window.addEventListener("message", this.newMessage);
    },
    async addCard() {
      this.$refs.addcard.addCardDialog();
      window.addEventListener("message", this.newMessage);
    },
    async addCameper() {
      this.$refs.addcamper.addCameperDialog = true;
      window.addEventListener("message", this.newMessage);
    },
    async send() {
      if (this.$refs.form.validate())
        try {
          this.loading1 = true;
          if (this.reply === true) {
            if(this.emailCredits < 2) throw new TypeError('Not Enough Credit')
            this.reply = 2;
          }
          if (this.reply === false) this.reply = 1;
          await MessagesService.newMessage({
            camperId: this.selectedCampers,
            from: this.from,
            message: this.message,
            replyAmount: this.reply
          });
          this.loading1 = false;
          this.messageSent = true;
        } catch (error) {
          this.loading1 = false;
          alert(error);
        }
    },
    async sendAnother() {
      this.from = this.selectedCampers = null;
      this.message = null;
      this.reply = false;
      this.userData = (await userService.user()).data;
      this.emailCredits = this.userData.user[0].EmailCredits;
      this.from =
        this.userData.user[0].FirstName + " " + this.userData.user[0].LastName;
      this.messageSent = false;
    },
    async newMessage(message) {
      window.removeEventListener("message", message);
      if (message.data.includes("refresh") == true) {
        this.userData = (await userService.user()).data;
        this.emailCredits = this.userData.user[0].EmailCredits;
        this.campers = this.userData.usercampers;
        this.userData.usercampers.forEach(
          Camper => (Camper.fullName = Camper.firstName + " " + Camper.lastName)
        );
        this.usercards = this.userData.usercards;
        this.from =
          this.userData.user[0].FirstName +
          " " +
          this.userData.user[0].LastName;
        this.nocampers = false;
        this.errorcolor = null;
        if (this.campers.length == 0 || null) {
          this.nocampers = true;
          this.errorcolor = "red";
        }
      }
    }
  }
};
</script>
<style scoped>
/*to be removed */
.mainBody {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 3%;
}

.theme--light.v-list {
  background: #FFFFFF;

}
</style>